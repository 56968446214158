import Vue from 'vue';

const toasts = Vue.observable({
  localToasts: [],
});

export function getToasts() {
  return toasts.localToasts;
}

export function addToast(toast) {
  toasts.localToasts = [...toasts.localToasts, toast];
}

export function clearToast(id) {
  toasts.localToasts = toasts.localToasts.filter((toast) => toast.id !== id);
}
