<template>
  <component
    :is="toast.url ? 'a' : 'div'"
    v-bind="[toast.url ? { href: toast.url } : {}]"
  >
    <div
      :class="[
        'notification alert  pointer-events-auto',
        `alert-${toast.type}`,
      ]"
      @click="triggerClose"
    >
      <p v-if="toast.title">
        <strong>{{ toast.title }}</strong>
      </p>
      <p v-if="toast.message">{{ toast.message }}</p>
    </div>
  </component>
</template>

<script>
export default {
  name: 'Toast',
  props: ['toast'],

  mounted() {
    const timeout = this.toast.timeout || 5000;
    this.timer = setTimeout(() => {
      this.triggerClose();
    }, timeout);
  },

  methods: {
    triggerClose() {
      clearTimeout(this.timer);
      this.$emit('remove-toast', this.toast);
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  cursor: pointer;
  padding: 10px;
  width: 300px;
}
</style>
